@font-face {
  font-family: 'basier_circle';
  src: url('../assets/fonts/basier_circle_regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/basier_circle_regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'basier_circle';
  src: url('../assets/fonts/basier_circle_medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/basier_circle_medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'menlo';
  src: url('../assets/fonts/menlo-regular.woff2') format('woff2'),
    url('../assets/fonts/menlo-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
