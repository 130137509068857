@import '~codemirror/lib/codemirror.css';
@import 'fonts.css';

html {
  position: relative;
  height: 100%;
  color: #333;
}

body {
  height: 100%;
  background-color: var(--body-background-color);
  line-height: 1.43;
  font-size: 0.875rem;

  /* System font stack: https://bitsofco.de/the-new-system-font-stack */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  /* System font stack with Apideck font */
  /* font-family: 'basier_circle', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

.dimmed {
  filter: grayscale(100%);
  opacity: 0.2;
}

input:required {
  box-shadow: none;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: 700;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

textarea::placeholder {
  color: #a7a7a7;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input,
select {
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

input:disabled {
  background-color: #eee;
}

.inputSuffix,
.inputPrefix {
  padding: 10px;
  background-color: #f4f5f6;
  border: 1px solid #ccc;
}

.inputSuffix {
  border-left: none;
  border-radius: 0 3px 3px 0;
}

.inputPrefix {
  border-right: none;
  border-radius: 3px 0 0 3px;
}

fieldset p,
.CodeMirror-code {
  font-size: 14px;
}

.fieldset :first-child {
  margin-top: 0;
}

.CodeMirror {
  height: 100%;
  min-height: 200px;
}

.Select-control {
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  margin-bottom: 15px;
  text-align: left;
}

label {
  text-align: left;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #5a5760;
}

label.required:after {
  content: '*';
  margin-left: 5px;
  color: #ff5630;
}

button {
  padding: 12px 40px;
  color: #fff;
  border-radius: 3px;
  background-color: var(--link-color);
  border: 1px solid var(--link-color);
}

button.delete {
  color: #fff;
  background-color: #e10050;
}

button.delete:hover {
  background-color: #e10050;
}

button:hover {
  cursor: pointer;
}

.fieldWrapper {
  margin-bottom: 16px;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

form.filters {
  margin-bottom: 10px;
}

.sticky-submit-container {
  background: white;
  position: sticky;
  padding: 16px 32px;
  bottom: 0px;
  margin-top: 32px;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: -32px;
  box-shadow: 0px 0px 10px rgba(79, 83, 108, 0.08);
  z-index: 2;
}

.sticky-submit-container.align-left {
  margin-right: 0px;
  border-radius: 0px 5px 0px 0px;
}

/* Navigation */

.navigation .title a {
  border-right: 1px solid #605c72;
  padding-right: 30px;
}

.navigation .title .icon {
  width: 32px;
  height: 32px;
  border-radius: 2px;
}

.navigation .title h1 {
  font-size: 1.2em;
  font-weight: normal;
}

.navigation .title a {
  text-decoration: none;
  color: #ffffffa1;
  font-size: 15px;
}

nav a {
  color: #ffffffa1;
  font-weight: 500;
  text-decoration: none;
}

nav a:hover,
nav a.active {
  color: #fff;
  transition: color 200ms ease-in-out;
}

nav a.active {
  font-weight: 500;
  color: #fff;
}

nav ul {
  list-style: none;
}

.navigation ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-left: 10px;
  height: 60px;
}

.navigation ul li {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.navigation ul li svg {
  float: left;
  margin: -2px 5px 0 2px;
}

.navigation ul li svg.external {
  float: right;
  margin: 6px 0 0 4px;
}

/* Header */

.bigAvatar {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.card h1 {
  font-size: 24px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

/* Forms */

fieldset {
  border: 0px solid silver;
  margin: 0;
  padding: 0.35em 0 0.75em 0;
  min-width: auto;
}

fieldset h1 {
  font-size: 24px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  color: #333;
}

.servicesRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.form-element {
  margin-bottom: 15px;
}

.errors {
  color: #ff5630;
  display: block;
  font-size: 14px;
}

ul.developers {
  list-style: none;
  list-style-type: none;
  padding-left: 0px;
}

ul.developers li {
  margin-bottom: 8px;
}

form span.errors {
  color: #ff5630;
  text-align: left;
  display: block;
  margin-bottom: 20px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.text-align--center {
  text-align: center;
}

.ribbon::after {
  -webkit-transform: rotate(37deg);
  transform: rotate(37deg);
  font-size: 0.68rem;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  background-color: #cacaca;
  text-transform: uppercase;
  padding: 5px 100px;
  position: absolute;
  right: -90px;
  top: 20px;
}

.ribbon.upcoming::after {
  content: 'Upcoming';
}

.ribbon.zapierBeta::after {
  content: 'Upcoming';
}

table td img {
  max-width: 24px;
  max-height: 24px;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  padding: 10px 20px;
  line-height: 24px;
}

.pill {
  border-radius: 20px;
  padding: 3px 12px;
  font-size: 13px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 8px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  background-color: #5c51ce;
  color: white;
}

a .icon-container {
  opacity: 0.2;
}

a:hover .icon-container {
  opacity: 1;
}

/* Hide broken error overlay   */
body > iframe {
  display: none !important;
}

/* Clean up styles for react vault */
#headlessui-portal-root button.items-center {
  background-color: none;
  border: none;
}

#headlessui-portal-root .relative.inline-block.font-medium button {
  border: none;
}

#headlessui-portal-root button.border-b-2 {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
}
